import React, { useEffect, useRef, useState } from "react";
import {
  makeStyles,
  Card as MaterialCard,
  CardContent,
} from "@material-ui/core";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 300,
    height: "58vh",
    minHeight: "40vh",
    position: "fixed",
    width: "100%",
    maxWidth: "500px",
    bottom: 0,
    borderTopLeftRadius: "35px",
    borderTopRightRadius: "35px",
    padding: theme.spacing(3, 4),
    overflowY: "scroll",
    boxShadow: "3px -3px 6px #0000001A",
    maxHeight: "300vh",
  },
  content: {
    padding: 0,
    paddingBottom: "0 !important",
    height: "100%",
  },
  title: {
    fontWeight: "bold",
    fontSize: "20px",
  },
  virtualKeyboardIsVisible: {
    minHeight: "100vh",
  },
  desktopDimension: {
    // bottom: "16vh",
    borderRadius: "35px",
    // height: "60vh",
    overflowY: "scroll",
  },
}));

const BottomCard = ({ children }) => {
  const classes = useStyles();
  const [height, setHeight] = useState(window.innerHeight);
  const [width, setWidth] = useState(window.innerWidth);
  const initialHeight = useRef(window.innerHeight);
  const handleWindowSizeChange = () => {
    setHeight(window.innerHeight);
    setWidth(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);
  return (
    <MaterialCard
      className={clsx(
        classes.root,
        initialHeight.current !== height
          ? classes.virtualKeyboardIsVisible
          : null,
        width >= 768 ? classes.desktopDimension : null
      )}
    >
      <CardContent className={classes.content}>{children}</CardContent>
    </MaterialCard>
  );
};

export default BottomCard;
