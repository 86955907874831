import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom/cjs/react-router-dom';
import BigLogo from './components/UI/Logo/BigLogo';
import SmallLogo from './components/UI/Logo/SmallLogo';
import HorizontalStepper from './components/UI/Stepper';

const useStyles = makeStyles((theme) => ({
  root: (props) => ({
    backgroundColor: theme.palette.background.main,
    height: props.disableBasicCSS ? 'unset' : '100vh',
    overflow: props.disableBasicCSS ? 'scroll' : 'hidden !important',
    position: props.disableBasicCSS ? 'relative' : 'fixed',
    width: '100%',
    maxWidth: '500px',
  }),
  stepper: {
    marginTop: theme.spacing(2),
  },
  desktopContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
}));

const Layout = ({
  displayStepper = true,
  disableBasicCSS = false,
  logoSize = 'small',
  children,
  step,
}) => {
  const classes = useStyles({ disableBasicCSS });
  const [width, setWidth] = useState(window.innerWidth);
  const { promoCode } = useParams();
  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);
  if (width >= 768)
    return (
      <Box className={classes.desktopContainer}>
        <Box className={classes.root}>
          {logoSize === 'small' ? <SmallLogo /> : <BigLogo />}
          {displayStepper && (
            <HorizontalStepper
              className={classes.stepper}
              list={[1, 2, 3, 4, 5]}
              activeStep={step}
            />
          )}
          {children}
        </Box>
      </Box>
    );
  else
    return (
      <Box className={classes.root}>
        {logoSize === 'small' ? <SmallLogo /> : <BigLogo />}

        {displayStepper && (
          <HorizontalStepper
            className={classes.stepper}
            list={[1, 2, 3, 4, 5]}
            activeStep={step}
          />
        )}
        {children}
      </Box>
    );
};

export default Layout;
