import { api } from './config';

export const fetchPromoCodeByCode = async (code) =>
  await api.get(`/promo-codes/${code}`, {});

export const fetchPrice = async (code) =>
  await api.post(`/payment/price`, { code });

export const patchTempPromoCode = async (promoCode) =>
  await api.patch(`/users/temp-promo-code`, {
    promoCode,
  });

export const bumpPromoCode = async (promoCode) =>
  await api.post(`/promo-codes/${promoCode}/bump`, {});
