import { createSlice } from '@reduxjs/toolkit';
import { setLocalStorageStepNumber } from '../../utils/localStorage';

export const formSlice = createSlice({
  name: 'form',
  initialState: {
    currentStep: 0,
    formData: {},
  },
  reducers: {
    setStep: (state, action) => {
      state.currentStep = action.payload;
      setLocalStorageStepNumber(action.payload);
    },
    saveStep: (state, action) => {
      const obj = { [action.payload.step]: action.payload.data };
      const tempForm = { ...state.formData, ...obj };
      return { ...state, formData: tempForm };
    },
    loadSteps: (state, action) => {
      let obj = {};
      for (const i of action.payload) {
        obj = { ...obj, [i.step]: JSON.parse(i.data) };
      }

      return { ...state, formData: obj };
    },
    getState: (state) => {
      return state;
    },
  },
});

export const { setStep, getState, saveStep, loadSteps, updateStep } =
  formSlice.actions;

export default formSlice.reducer;
