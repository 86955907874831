import { api } from './config';

const UserAuthorizationHeaders = (sendFile = false) => ({
  'Content-Type': sendFile
    ? 'multipart/form-data, application/json'
    : 'application/json',
});

export const saveForm = async (stepData) =>
  api
    .post(`/forms`, stepData, {
      headers: UserAuthorizationHeaders(),
    })
    .catch((err) => console.log);

export const updateForm = async (stepData) =>
  api
    .patch(`/forms`, stepData, {
      headers: UserAuthorizationHeaders(),
    })
    .catch((err) => console.log);

export const uploadFile = (file, onError) => {
  if (file && !(file instanceof String)) {
    const formData = new FormData();
    formData.append('file', file);
    return api
      .post('/forms/upload-file', formData, {
        headers: UserAuthorizationHeaders(),
      })
      .then((res) => res.data)
      .catch((err) => {
        console.log(err);
        if (onError) onError(err);
        throw err;
      });
  } else {
    return Promise.resolve();
  }
};

export const fetchFormsByUserId = async (userId) =>
  api
    .get(`/users/${userId}/forms`, {
      headers: UserAuthorizationHeaders(),
    })
    .catch((err) => console.log);

export const saveFile = async (file, step) => {
  const formData = new FormData();
  formData.append(step, file);
  return api
    .post('/forms/files', formData, {
      headers: UserAuthorizationHeaders(true),
    })
    .then((res) => res.data)
    .catch((error) => error);
};

export const fetchFormsByRecordIdAndStep = async (recordId, step) =>
  api
    .get(`/forms/${step}/records/${recordId}`, {
      headers: UserAuthorizationHeaders(),
    })
    .catch((err) => err);
