import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core/styles';
import { randomBytes } from 'crypto';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import App from './App';
import Loader from './components/UI/Loader';
import Theme from './config/theme';
import './index.css';
import { persistor, store } from './redux/store';
import reportWebVitals from './reportWebVitals';

ReactDOM.render(
  <React.StrictMode>
    <React.Suspense fallback={<Loader />}>
      <BrowserRouter>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <ThemeProvider theme={Theme}>
              <CssBaseline />
              <App />
            </ThemeProvider>
          </PersistGate>
        </Provider>
      </BrowserRouter>
    </React.Suspense>
  </React.StrictMode>,
  document.getElementById('root')
);

const script = document.createElement('script');
const nonce = randomBytes(16).toString('base64');

script.type = 'text/javascript';
script.src =
  'https://desk.zoho.eu/org/20079559011/gc/widgets/89338000021431110/embed/script';
script.nonce = nonce;
script.defer = true;

const scriptCookie = document.createElement('script');

scriptCookie.id = 'cookieyes';
scriptCookie.type = 'text/javascript';
scriptCookie.src =
  'https://cdn-cookieyes.com/client_data/8efd7a1e690a7ca6b7007153/script.js';

document.body.appendChild(script);
document.head.appendChild(scriptCookie);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
