import { lazy, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { api } from './api/config';
import { fetchFormsByUserId } from './api/step';
import ProtectedRoute from './components/protectedRoute';
import withForceStepRedirection from './hoc/withForceStepRedirection';
import Step1 from './pages/1-Step1';
import { getState, loadSteps } from './redux/form/formSlice';
import { logout, rehydrateUser } from './redux/user/userSlice';
import { getAllActivities } from './services/activities';

const SignIn = lazy(() => import('./pages/0-Security/SignIn'));
const SignUp = lazy(() => import('./pages/0-Security/SignUp'));
const ForgottenPassword = lazy(() =>
  import('./pages/0-Security/ForgottenPassword')
);
const ResetPassword = lazy(() => import('./pages/0-Security/ResetPassword'));
const PlaceOfBirth = lazy(() => import('./pages/2-PlaceOfBirth'));
const Nationality = lazy(() => import('./pages/3-Nationality'));
const Activity = lazy(() => import('./pages/4-Activity'));
const CommercialName = lazy(() => import('./pages/5-CommercialName'));
const ContactDetails = lazy(() => import('./pages/6-ContactDetails'));
const Address = lazy(() => import('./pages/7-Address'));
const SecondAddress = lazy(() => import('./pages/8-SecondAddress'));
const Resume = lazy(() => import('./pages/9-Resume'));
const Payment = lazy(() => import('./pages/10-Payment'));
const SocialSecurityNumber = lazy(() =>
  import('./pages/11-SocialSecurityNumber')
);
const SocialCotisation = lazy(() => import('./pages/12-SocialCotisation'));
const RevenuRhythm = lazy(() => import('./pages/13-RevenuRhythm'));
const Acre = lazy(() => import('./pages/14-Acre'));
const AcreJustificatif = lazy(() => import('./pages/24-AcreJustificatif'));
const FullScreen = lazy(() => import('./components/FullScreen'));

const PouvoirConfieInfo = lazy(() => import('./pages/15-PouvoirConfieInfo'));
const PouvoirConfie = lazy(() => import('./pages/16-PouvoirConfie'));
const PreuveIdentiteInfo = lazy(() => import('./pages/17-PreuveIdentite/info'));
const PreuveIdentite = lazy(() => import('./pages/17-PreuveIdentite'));
const CertificateNonConvictionInfo = lazy(() =>
  import('./pages/18-CertificateNonConvictionInfo')
);
const CertificateNonConviction = lazy(() =>
  import('./pages/19-CertificateNonConviction')
);
const Diploma = lazy(() => import('./pages/20-diploma'));
const CertificateResidenceHostInfo = lazy(() =>
  import('./pages/23-CertificateResidenceHost/info')
);
const CertificateResidenceHost = lazy(() =>
  import('./pages/23-CertificateResidenceHost')
);
const ValidationFile = lazy(() => import('./pages/21-Validation-dossier'));
const CreateMicroEnterprise = lazy(() =>
  import('./pages/22-CreateMicroEnterprise')
);

function App() {
  const [loading, setLoading] = useState(true);

  const user = useSelector((state) => state.user.currentUser);
  const dispatch = useDispatch();

  Promise.resolve(getAllActivities());

  useEffect(() => {
    async function fetchData() {
      if (user && user.token) {
        api.setAccessToken(user.token);
        const result = await fetchFormsByUserId(user.id);
        if (result.data) {
          await dispatch(loadSteps(result.data));
          await dispatch(getState());
        }
      }
    }
    fetchData();
  }, [user]);

  useEffect(() => {
    let savedUser = localStorage.getItem('user');
    if (savedUser) {
      savedUser = JSON.parse(savedUser);
      const now = new Date();
      const tokenExpiration = new Date(0);
      tokenExpiration.setUTCSeconds(savedUser.exp);
      if (now.getTime() > tokenExpiration.getTime()) {
        dispatch(logout());
      } else {
        dispatch(rehydrateUser(savedUser));
      }
    }
    setLoading(false);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) {
    return <></>;
  }
  return (
    <Switch>
      <Route path='/sign-up' component={SignUp} exact />
      <Route path='/sign-in' component={SignIn} exact />
      <Route path='/forgotten-password' component={ForgottenPassword} />
      <Route path='/reset-password' component={ResetPassword} exact />
      <ProtectedRoute path='/' component={Step1} exact />
      <ProtectedRoute
        path='/place-of-birth/:operation?'
        component={PlaceOfBirth}
        exact
      />
      <ProtectedRoute
        path='/nationality/:operation?'
        component={Nationality}
        exact
      />
      <ProtectedRoute path='/activity/:operation?' component={Activity} exact />
      <ProtectedRoute
        path='/commercial-name/:operation?'
        component={CommercialName}
        exact
      />
      <ProtectedRoute
        path='/contact-details/:operation?'
        component={ContactDetails}
        exact
      />
      <ProtectedRoute path='/address/:operation?' component={Address} exact />
      <ProtectedRoute
        path='/second-address/:operation?'
        component={SecondAddress}
        exact
      />
      <ProtectedRoute
        path='/social-security-number/:operation?'
        component={SocialSecurityNumber}
        exact
      />
      <ProtectedRoute
        path='/social-cotisation/:operation?'
        component={SocialCotisation}
        exact
      />
      <ProtectedRoute
        path='/revenu-rhythm/:operation?'
        component={RevenuRhythm}
        exact
      />
      <ProtectedRoute path='/acre/:operation?' component={Acre} exact />
      <ProtectedRoute
        path='/acre-justificatif/:operation?'
        component={AcreJustificatif}
        exact
      />
      <ProtectedRoute
        path='/pouvoir-confie/:operation?'
        component={PouvoirConfie}
        exact
      />
      <ProtectedRoute
        path='/pouvoir-confie-info'
        component={PouvoirConfieInfo}
        exact
      />

      <ProtectedRoute
        path='/identity-info'
        component={PreuveIdentiteInfo}
        exact
      />
      <ProtectedRoute
        path='/identity/:operation?'
        component={PreuveIdentite}
        exact
      />
      <ProtectedRoute
        path='/attestation-de-non-condamnation-info'
        component={CertificateNonConvictionInfo}
        exact
      />
      <ProtectedRoute
        path='/attestation-de-non-condamnation/:operation?'
        component={CertificateNonConviction}
        exact
      />
      <ProtectedRoute path='/diplome/:operation?' component={Diploma} exact />
      <ProtectedRoute path='/full-screen' component={FullScreen} exact />
      <ProtectedRoute
        path='/attestation-domicile-info'
        component={CertificateResidenceHostInfo}
        exact
      />
      <ProtectedRoute
        path='/attestation-domicile/:operation?'
        component={CertificateResidenceHost}
        exact
      />
      <ProtectedRoute path='/resume' component={Resume} />
      <ProtectedRoute path='/payment/:operation?' component={Payment} />
      <ProtectedRoute
        path='/validation-dossier'
        component={ValidationFile}
        exact
      />
      <ProtectedRoute
        path='/validation-micro-entreprise'
        component={CreateMicroEnterprise}
        exact
      />
    </Switch>
  );
}

export default withForceStepRedirection(App);
