import { signIn } from "../api/user";

export const STEPS_INFOS = {
  // PERSONAL INFOS STEPSS
  firstStep: { url: '/', stepperValue: 0, order: 0 },
  signUp: { url: '/sign-up', stepperValue: -1000, order: -1000 },
  signIn: { url: '/sign-in', stepperValue: -1001, order: -1001 },
  forgotPassword: { url: '/forgotten-password', stepperValue: -1002, order: -1002 },
  resetPassword: { url: '/reset-password', stepperValue: -1003, order: -1003 },
  placeOfBirth: { url: '/place-of-birth', stepperValue: 0, order: 1 },
  nationality: { url: '/nationality', stepperValue: 0, order: 2 },

  // ACTIVITY INFOS STEPS
  activity: { url: '/activity', stepperValue: 1, order: 3 },
  commercialName: { url: '/commercial-name', stepperValue: 1, order: 4 },
  contactDetails: { url: '/contact-details', stepperValue: 1, order: 5 },
  address: { url: '/address', stepperValue: 1, order: 6 },
  secondAddress: { url: '/second-address', stepperValue: 1, order: 7 },
  resume: { url: '/resume', stepperValue: 1, order: 8 },

  // PAYMENT STEPS
  payment: { url: '/payment', stepperValue: 2, order: 9 },

  // ACTIVITY DETAILS STEPS
  socialSecurityNumber: {
    url: '/social-security-number',
    stepperValue: 3,
    order: 10,
  },
  socialCotisation: { url: '/social-cotisation', stepperValue: 3, order: 11 },
  revenuRhythm: { url: '/revenu-rhythm', stepperValue: 3, order: 12 },
  acre: { url: '/acre', stepperValue: 3, order: 13 },

  // DOCS STEPS
  pouvoirConfieInfo: {
    url: '/pouvoir-confie-info',
    stepperValue: 4,
    order: 14,
  },
  pouvoirConfie: { url: '/pouvoir-confie', stepperValue: 4, order: 15 },
  identityInfo: { url: '/identity-info', stepperValue: 4, order: 16 },
  identity: { url: '/identity', stepperValue: 4, order: 17 },
  proofOfResidenceInfo: {
    url: '/attestation-domicile-info',
    stepperValue: 4,
    order: 18,
  },
  proofOfResidence: {
    url: '/attestation-domicile',
    stepperValue: 4,
    order: 19,
  },
  proofOfNonConvictionInfo: {
    url: '/attestation-de-non-condamnation-info',
    stepperValue: 4,
    order: 20,
  },
  proofOfNonConviction: {
    url: '/attestation-de-non-condamnation',
    stepperValue: 4,
    order: 21,
  },
  diploma: { url: '/diplome', stepperValue: 5, order: 22 },
  createCompany: {
    url: '/validation-micro-entreprise',
    stepperValue: 5,
    order: 23,
  },
  validationDossier: {
    url: '/validation-dossier',
    stepperValue: 5,
    order: 24,
  },
  acreJustificatif: { url: '/acre-justificatif', stepperValue: 3, order: 25 },
};

export const NATIONALITY = [
  { key: 'Française', value: 'FRA' },
  { key: 'Afghane', value: 'AFG' },
  { key: 'Albanaise', value: 'ALB' },
  { key: 'Algérienne', value: 'DZA' },
  { key: 'Allemande', value: 'DEU' },
  { key: 'Américaine', value: 'USA' },
  { key: 'Andorrane', value: 'AND' },
  { key: 'Angolaise', value: 'AGO' },
  { key: 'Antiguaise et barbudienne', value: 'ATG' },
  { key: 'Argentine', value: 'ARG' },
  { key: 'Arménienne', value: 'ARM' },
  { key: 'Australienne', value: 'AUS' },
  { key: 'Autrichienne', value: 'AUT' },
  { key: 'Azerbaïdjanaise', value: 'AZE' },
  { key: 'Bahamienne', value: 'BHS' },
  { key: 'Bahreinienne', value: 'BHR' },
  { key: 'Bangladaise', value: 'BGD' },
  { key: 'Barbadienne', value: 'BRB' },
  { key: 'Belge', value: 'BEL' },
  { key: 'Belizienne', value: 'BLZ' },
  { key: 'Béninoise', value: 'BEN' },
  { key: 'Bhoutanaise', value: 'BTN' },
  { key: 'Biélorusse', value: 'BLR' },
  { key: 'Birmane', value: 'MMR' },
  { key: 'Bissau-Guinéenne', value: 'GNB' },
  { key: 'Bolivienne', value: 'BOL' },
  { key: 'Bosnienne', value: 'BIH' },
  { key: 'Botswanaise', value: 'BWA' },
  { key: 'Brésilienne', value: 'BRA' },
  { key: 'Britannique', value: 'GBR' },
  { key: 'Bruneienne', value: 'BRN' },
  { key: 'Bulgare', value: 'BGR' },
  { key: 'Burkinabé', value: 'BFA' },
  { key: 'Burundaise', value: 'BDI' },
  { key: 'Cambodgienne', value: 'KHM' },
  { key: 'Camerounaise', value: 'CMR' },
  { key: 'Canadienne', value: 'CAN' },
  { key: 'Cap-verdienne', value: 'CPV' },
  { key: 'Centrafricaine', value: 'CAF' },
  { key: 'Chilienne', value: 'CHL' },
  { key: 'Chinoise', value: 'CHN' },
  { key: 'Chypriote', value: 'CYP' },
  { key: 'Colombienne', value: 'COL' },
  { key: 'Comorienne', value: 'COM' },
  { key: 'Congolaise', value: 'COG' },
  { key: 'Costaricaine', value: 'CRI' },
  { key: 'Croate', value: 'HRV' },
  { key: 'Cubaine', value: 'CUB' },
  { key: 'Danoise', value: 'DNK' },
  { key: 'Djiboutienne', value: 'DJI' },
  { key: 'Dominicaine', value: 'DOM' },
  { key: 'Dominiquaise', value: 'DMA' },
  { key: 'Égyptienne', value: 'EGY' },
  { key: 'Émirienne', value: 'ARE' },
  { key: 'Équato-Guinéenne', value: 'GNQ' },
  { key: 'Équatorienne', value: 'ECU' },
  { key: 'Érythréenne', value: 'ERI' },
  { key: 'Espagnole', value: 'ESP' },
  { key: 'Est-Timoraise', value: 'TLS' },
  { key: 'Estonienne', value: 'EST' },
  { key: 'Éthiopienne', value: 'ETH' },
  { key: 'Fidjienne', value: 'FJI' },
  { key: 'Finlandaise', value: 'FIN' },
  { key: 'Gabonaise', value: 'GAB' },
  { key: 'Gambienne', value: 'GMB' },
  { key: 'Géorgienne', value: 'GEO' },
  { key: 'Ghanéenne', value: 'GHA' },
  { key: 'Grecque', value: 'GRC' },
  { key: 'Grenadienne', value: 'GRD' },
  { key: 'Guatémaltèque', value: 'GTM' },
  { key: 'Guinéenne', value: 'GIN' },
  { key: 'Guyanienne', value: 'GUY' },
  { key: 'Haïtienne', value: 'HTI' },
  { key: 'Hellénique', value: 'GRC' },
  { key: 'Hondurienne', value: 'HND' },
  { key: 'Hongroise', value: 'HUN' },
  { key: 'Indienne', value: 'IND' },
  { key: 'Indonésienne', value: 'IDN' },
  { key: 'Irakienne', value: 'IRQ' },
  { key: 'Irlandaise', value: 'IRL' },
  { key: 'Islandaise', value: 'ISL' },
  { key: 'Israélienne', value: 'ISR' },
  { key: 'Italienne', value: 'ITA' },
  { key: 'Ivoirienne', value: 'CIV' },
  { key: 'Jamaïcaine', value: 'JAM' },
  { key: 'Japonaise', value: 'JPN' },
  { key: 'Jordanienne', value: 'JOR' },
  { key: 'Kazakhstanaise', value: 'KAZ' },
  { key: 'Kényane', value: 'KEN' },
  { key: 'Kirghize', value: 'KGZ' },
  { key: 'Kiribatienne', value: 'KIR' },
  { key: 'Kittitienne-et-Nevicienne', value: 'KNA' },
  { key: 'Kosovare', value: 'XKX' },
  { key: 'Koweïtienne', value: 'KWT' },
  { key: 'Laotienne', value: 'LAO' },
  { key: 'Lesothane', value: 'LSO' },
  { key: 'Lettone', value: 'LVA' },
  { key: 'Libanaise', value: 'LBN' },
  { key: 'Libérienne', value: 'LBR' },
  { key: 'Libyenne', value: 'LBY' },
  { key: 'Liechtensteinoise', value: 'LIE' },
  { key: 'Lituanienne', value: 'LTU' },
  { key: 'Luxembourgeoise', value: 'LUX' },
  { key: 'Macédonienne', value: 'MKD' },
  { key: 'Malaisienne', value: 'MYS' },
  { key: 'Malawienne', value: 'MWI' },
  { key: 'Maldivienne', value: 'MDV' },
  { key: 'Malgache', value: 'MDG' },
  { key: 'Malienne', value: 'MLI' },
  { key: 'Maltaise', value: 'MLT' },
  { key: 'Marocaine', value: 'MAR' },
  { key: 'Marshallaise', value: 'MHL' },
  { key: 'Mauricienne', value: 'MUS' },
  { key: 'Mauritanienne', value: 'MRT' },
  { key: 'Mexicaine', value: 'MEX' },
  { key: 'Micronésienne', value: 'FSM' },
  { key: 'Moldave', value: 'MDA' },
  { key: 'Monégasque', value: 'MCO' },
  { key: 'Mongole', value: 'MNG' },
  { key: 'Monténégrine', value: 'MNE' },
  { key: 'Mozambicaine', value: 'MOZ' },
  { key: 'Namibienne', value: 'NAM' },
  { key: 'Nauruane', value: 'NRU' },
  { key: 'Néerlandaise', value: 'NLD' },
  { key: 'Néo-Zélandaise', value: 'NZL' },
  { key: 'Népalaise', value: 'NPL' },
  { key: 'Nicaraguayenne', value: 'NIC' },
  { key: 'Nigériane', value: 'NGA' },
  { key: 'Nigérienne', value: 'NER' },
  { key: 'Nord-coréenne', value: 'PRK' },
  { key: 'Norvégienne', value: 'NOR' },
  { key: 'Omanaise', value: 'OMN' },
  { key: 'Ougandaise', value: 'UGA' },
  { key: 'Ouzbèke', value: 'UZB' },
  { key: 'Pakistanaise', value: 'PAK' },
  { key: 'Palau', value: 'PLW' },
  { key: 'Palestinienne', value: 'PSE' },
  { key: 'Panaméenne', value: 'PAN' },
  { key: 'Papouane-Néo-Guinéenne', value: 'PNG' },
  { key: 'Paraguayenne', value: 'PRY' },
  { key: 'Péruvienne', value: 'PER' },
  { key: 'Philippine', value: 'PHL' },
  { key: 'Polonaise', value: 'POL' },
  { key: 'Portoricaine', value: 'PRI' },
  { key: 'Portugaise', value: 'PRT' },
  { key: 'Qatarienne', value: 'QAT' },
  { key: 'Roumaine', value: 'ROU' },
  { key: 'Russe', value: 'RUS' },
  { key: 'Rwandaise', value: 'RWA' },
  { key: 'Saint-Lucienne', value: 'LCA' },
  { key: 'Saint-Marinaise', value: 'SMR' },
  { key: 'Saint-Vincentaise-et-Grenadine', value: 'VCT' },
  { key: 'Salomonaise', value: 'SLB' },
  { key: 'Salvadorienne', value: 'SLV' },
  { key: 'Samoane', value: 'WSM' },
  { key: 'Santoméenne', value: 'STP' },
  { key: 'Saoudienne', value: 'SAU' },
  { key: 'Sénégalaise', value: 'SEN' },
  { key: 'Serbe', value: 'SRB' },
  { key: 'Seychelloise', value: 'SYC' },
  { key: 'Sierra-Léonaise', value: 'SLE' },
  { key: 'Singapourienne', value: 'SGP' },
  { key: 'Slovaque', value: 'SVK' },
  { key: 'Slovène', value: 'SVN' },
  { key: 'Somalienne', value: 'SOM' },
  { key: 'Soudanaise', value: 'SDN' },
  { key: 'Sri-Lankaise', value: 'LKA' },
  { key: 'Sud-Africaine', value: 'ZAF' },
  { key: 'Sud-Coréenne', value: 'KOR' },
  { key: 'Suédoise', value: 'SWE' },
  { key: 'Suisse', value: 'CHE' },
  { key: 'Surinamaise', value: 'SUR' },
  { key: 'Swazie', value: 'SWZ' },
  { key: 'Syrienne', value: 'SYR' },
  { key: 'Tadjike', value: 'TJK' },
  { key: 'Taiwanaise', value: 'TWN' },
  { key: 'Tanzanienne', value: 'TZA' },
  { key: 'Tchadienne', value: 'TCD' },
  { key: 'Tchèque', value: 'CZE' },
  { key: 'Thaïlandaise', value: 'THA' },
  { key: 'Togolaise', value: 'TGO' },
  { key: 'Tonguienne', value: 'TON' },
  { key: 'Trinidadienne', value: 'TTO' },
  { key: 'Tunisienne', value: 'TUN' },
  { key: 'Turkmène', value: 'TKM' },
  { key: 'Turque', value: 'TUR' },
  { key: 'Tuvaluane', value: 'TUV' },
  { key: 'Ukrainienne', value: 'UKR' },
  { key: 'Uruguayenne', value: 'URY' },
  { key: 'Vanuatuane', value: 'VUT' },
  { key: 'Vénézuélienne', value: 'VEN' },
  { key: 'Vietnamienne', value: 'VNM' },
  { key: 'Yéménite', value: 'YEM' },
  { key: 'Zambienne', value: 'ZMB' },
  { key: 'Zimbabwéenne', value: 'ZWE' },
];

export const NATIONALITIES_NOT_NEEDING_VISA = [
  'FRA',
  'DEU',
  'AND',
  'AUT',
  'BEL',
  'BLR',
  'BGR',
  'CYP',
  'HRV',
  'DNK',
  'ESP',
  'EST',
  'FIN',
  'GRC',
  'HUN',
  'IRL',
  'ITA',
  'XKX',
  'LVA',
  'LIE',
  'LTU',
  'LUX',
  'MLT',
  'MCO',
  'NLD',
  'NOR',
  'POL',
  'PRT',
  'ROU',
  'SMR',
  'SVK',
  'SVN',
  'SWE',
  'CHE',
  'CZE',
];

export const COUNTRIES = [
  'Afghanistan',
  'Afrique du Sud',
  'Albanie',
  'Algérie',
  'Allemagne',
  'Andorre',
  'Angola',
  'Anguilla',
  'Antarctique',
  'Antigua-et-Barbuda',
  'Antilles néerlandaises',
  'Arabie saoudite',
  'Argentine',
  'Arménie',
  'Aruba',
  'Australie',
  'Autriche',
  'Azerbaïdjan',
  'Bahamas',
  'Bahreïn',
  'Bangladesh',
  'Barbade',
  'Bélarus',
  'Belgique',
  'Belize',
  'Bénin',
  'Bermudes',
  'Bhoutan',
  'Bolivie',
  'Bosnie-Herzégovine',
  'Botswana',
  'Brésil',
  'Brunéi Darussalam',
  'Bulgarie',
  'Burkina Faso',
  'Burundi',
  'Cambodge',
  'Cameroun',
  'Canada',
  'Cap-Vert',
  'Ceuta et Melilla',
  'Chili',
  'Chine',
  'Chypre',
  'Colombie',
  'Comores',
  'Congo-Brazzaville',
  'Corée du Nord',
  'Corée du Sud',
  'Costa Rica',
  'Côte d’Ivoire',
  'Croatie',
  'Cuba',
  'Danemark',
  'Diego Garcia',
  'Djibouti',
  'Dominique',
  'Égypte',
  'El Salvador',
  'Émirats arabes unis',
  'Équateur',
  'Érythrée',
  'Espagne',
  'Estonie',
  'État de la Cité du Vatican',
  'États fédérés de Micronésie',
  'États-Unis',
  'Éthiopie',
  'Fidji',
  'Finlande',
  // 'France',
  'Gabon',
  'Gambie',
  'Géorgie',
  'Géorgie du Sud et les îles Sandwich du Sud',
  'Ghana',
  'Gibraltar',
  'Grèce',
  'Grenade',
  'Groenland',
  'Guadeloupe',
  'Guam',
  'Guatemala',
  'Guernesey',
  'Guinée',
  'Guinée équatoriale',
  'Guinée-Bissau',
  'Guyana',
  'Guyane française',
  'Haïti',
  'Honduras',
  'Hongrie',
  'Île Bouvet',
  'Île Christmas',
  'Île Clipperton',
  "Île de l'Ascension",
  'Île de Man',
  'Île Norfolk',
  'Îles Åland',
  'Îles Caïmans',
  'Îles Canaries',
  'Îles Cocos - Keeling',
  'Îles Cook',
  'Îles Féroé',
  'Îles Heard et MacDonald',
  'Îles Malouines',
  'Îles Mariannes du Nord',
  'Îles Marshall',
  'Îles Mineures Éloignées des États-Unis',
  'Îles Salomon',
  'Îles Turks et Caïques',
  'Îles Vierges britanniques',
  'Îles Vierges des États-Unis',
  'Inde',
  'Indonésie',
  'Irak',
  'Iran',
  'Irlande',
  'Islande',
  'Israël',
  'Italie',
  'Jamaïque',
  'Japon',
  'Jersey',
  'Jordanie',
  'Kazakhstan',
  'Kenya',
  'Kirghizistan',
  'Kiribati',
  'Koweït',
  'Laos',
  'Lesotho',
  'Lettonie',
  'Liban',
  'Libéria',
  'Libye',
  'Liechtenstein',
  'Lituanie',
  'Luxembourg',
  'Macédoine',
  'Madagascar',
  'Malaisie',
  'Malawi',
  'Maldives',
  'Mali',
  'Malte',
  'Maroc',
  'Martinique',
  'Maurice',
  'Mauritanie',
  'Mayotte',
  'Mexique',
  'Moldavie',
  'Monaco',
  'Mongolie',
  'Monténégro',
  'Montserrat',
  'Mozambique',
  'Myanmar',
  'Namibie',
  'Nauru',
  'Népal',
  'Nicaragua',
  'Niger',
  'Nigéria',
  'Niue',
  'Norvège',
  'Nouvelle-Calédonie',
  'Nouvelle-Zélande',
  'Oman',
  'Ouganda',
  'Ouzbékistan',
  'Pakistan',
  'Palaos',
  'Panama',
  'Papouasie-Nouvelle-Guinée',
  'Paraguay',
  'Pays-Bas',
  'Pérou',
  'Philippines',
  'Pitcairn',
  'Pologne',
  'Polynésie française',
  'Porto Rico',
  'Portugal',
  'Qatar',
  'R.A.S. chinoise de Hong Kong',
  'R.A.S. chinoise de Macao',
  'régions éloignées de l’Océanie',
  'République centrafricaine',
  'République démocratique du Congo',
  'République dominicaine',
  'République tchèque',
  'Réunion',
  'Roumanie',
  'Royaume-Uni',
  'Russie',
  'Rwanda',
  'Sahara occidental',
  'Saint-Barthélémy',
  'Saint-Kitts-et-Nevis',
  'Saint-Marin',
  'Saint-Martin',
  'Saint-Pierre-et-Miquelon',
  'Saint-Vincent-et-les Grenadines',
  'Sainte-Hélène',
  'Sainte-Lucie',
  'Samoa',
  'Samoa américaines',
  'Sao Tomé-et-Principe',
  'Sénégal',
  'Serbie',
  'Serbie-et-Monténégro',
  'Seychelles',
  'Sierra Leone',
  'Singapour',
  'Slovaquie',
  'Slovénie',
  'Somalie',
  'Soudan',
  'Sri Lanka',
  'Suède',
  'Suisse',
  'Suriname',
  'Svalbard et Île Jan Mayen',
  'Swaziland',
  'Syrie',
  'Tadjikistan',
  'Taïwan',
  'Tanzanie',
  'Tchad',
  'Terres australes françaises',
  "Territoire britannique de l'océan Indien",
  'Territoire palestinien',
  'Thaïlande',
  'Timor oriental',
  'Togo',
  'Tokelau',
  'Tonga',
  'Trinité-et-Tobago',
  'Tristan da Cunha',
  'Tunisie',
  'Turkménistan',
  'Turquie',
  'Tuvalu',
  'Ukraine',
  'Union européenne',
  'Uruguay',
  'Vanuatu',
  'Venezuela',
  'Viêt Nam',
  'Wallis-et-Futuna',
  'Yémen',
  'Zambie',
  'Zimbabwe',
];

export const TYPE_OF_VISA = [
  'Titre de séjour temporaire vie privée et familiale',
  'Titre de séjour Temporaire portant la mention Entrepreneur / Profession libérale',
  'Carte de résident',
  'Carte de résident longue durée - UE',
  'Carte de résident permanent',
  'Certificat de résidence algérien de 1 an profession non-salariée exercée',
  'Certificat de résidence algérien de 10 ans avoir le certificat 1 an',
  'Accord de retrait du Royaume-Uni de l’Union européenne',
  'Certificat de résidence Algérien étudiant - élève',
  'Autres',
];

export const OTHER_ACTIVITIES_WITH_MICRO = [
  { label: 'Salarié', value: 'Salarié' },
  { label: 'Etudiant', value: 'Etudiant' },
  {
    label: 'Sans activité (sans chômage)',
    value: 'Sans activité (sans chômage)',
  },
  {
    label: 'Sans activité (Avec chômage)',
    value: 'Sans activité (Avec chômage)',
  },
  {
    label: 'Je suis exclusivement micro-entrepreneur',
    value: 'Je suis exclusivement micro-entrepreneur',
  },
];
export const ACRE_SITUATIONS = [
  'Vous êtes inscrit à Pôle Emploi avec des allocations chômage.',
  'Vous êtes inscrit à pôle emploi sans allocations chômage.',
  "Vous êtes bénéficiaire du RSA ou d'une allocation de solidarité spécifique.",
  'Vous avez entre 18 et 25 ans.',
  'Vous êtes en situation de handicap.',
  'Vous créez une société dans un quartier prioritaire.',
  'Vous ne répondez à aucune de ces situations.',
];

export const ACRE_SITUATIONS_NEEDING_DOCUMENTS = [
  'Vous êtes inscrit à Pôle Emploi avec des allocations chômage.',
  'Vous êtes inscrit à pôle emploi sans allocations chômage.',
  "Vous êtes bénéficiaire du RSA ou d'une allocation de solidarité spécifique.",
  'Vous êtes en situation de handicap.',
  'Vous créez une société dans un quartier prioritaire.',
  'Vous ne répondez à aucune de ces situations.',
];
