import { createSlice } from "@reduxjs/toolkit";

export const stepperSlice = createSlice({
  name: "stepper",
  initialState: {
    activeStep: 0,
  },
  reducers: {
    moveToStep: (state, step) => {
      return { ...state, activeStep: step };
    },
    moveNextStep: (state) => {
      return { ...state, activeStep: state.activeStep + 1 };
    },
    moveBackStep: (state) => {
      return { ...state, activeStep: state.activeStep - 1 };
    },
  },
});

export const { moveToStep, moveNextStep, moveBackStep } = stepperSlice.actions;

export default stepperSlice.reducer;
