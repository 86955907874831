import { createTheme } from "@material-ui/core/styles";

const Theme = createTheme({
  palette: {
    background: {
      main: "#F8F9FF",
      blue: "#E6EFFA",
      button: "#0A62D00D",
    },
    blue: {
      main: "#0A62D0",
      border: "#0A62CF",
      text: "#0A62CF",
      secondary: "#2B8AC7",
      payementIcon: "#006FD7",
      light: "#F1F6FC",
    },
    green: {
      main: "#E6F4F1",
      dark: "#258D89",
      light: "#E6F4F1",
      lightDark: "#59928F",
    },
    purple: {
      main: "#EAEEFF",
    },
    yellow: {
      main: "#F9F8711A",
    },
    black: {
      main: "#444655",
    },
    gray: {
      background: "#44465533",
    },
    orange: {
      main: "#F18E80",
    },
    white: {
      main: "#fff",
    },
  },
  typography: {
    fontFamily: "Raleway, sans serif",
  },
});

export default Theme;
