import React from "react";
import smallLogo from '../../../assets/images/getbiz-logo.png';
import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3)
  },
  image: {
    width: '70px',
    height: 'auto'
  }
}));

const SmallLogo = () => {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <img className={classes.image} src={smallLogo} alt='Getbiz' />
    </Box>
  )
}

export default SmallLogo;