import { saveForm, updateForm } from '../api/step';
import { STEPS_INFOS } from '../constants';

const resultHandler = (result) => {
  if (typeof result.data?.data === 'object') {
    return { data: result.data?.data, step: result.data.step };
  }
  return { data: JSON.parse(result.data?.data), step: result.data.step };
};

export const saveOrUpdateForm = (operation, data, step, id) => {
  return new Promise((resolve) => {
    if (operation === 'update') {
      updateForm({ data, step, userId: id }).then((result) =>
        resolve(resultHandler(result))
      );
    } else
      saveForm({ data, step, userId: id }).then((result) =>
        resolve(resultHandler(result))
      );
  });
};

export const getOperation = (formData) => {
  return formData ? 'update' : 'save';
};

export const getStepPath = (stepNumber) => {
  const step = Object.values(STEPS_INFOS).find((step) => {
    return step.order === stepNumber;
  });
  if (!step) return STEPS_INFOS.firstStep.url;
  return step.url;
};
