import Step from '@material-ui/core/Step';
import StepConnector from '@material-ui/core/StepConnector';
import StepLabel from '@material-ui/core/StepLabel';
import Stepper from '@material-ui/core/Stepper';
import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import RadioButtonUncheckedOutlinedIcon from '@material-ui/icons/RadioButtonUncheckedOutlined';
import clsx from 'clsx';
const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: 'transparent',
  },

  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

export default function HorizontalStepper({
  list = ['1', '2', '3'],
  className,
  activeStep,
}) {
  const classes = useStyles();
  const steps = list;
  const theme = useTheme();
  const CustomConnector = withStyles({
    alternativeLabel: {
      alignSelf: 'center',
      left: 'calc(-50% + 16px)',
      right: 'calc(50% + 16px)',
    },
    line: {
      borderColor: theme.palette.blue.border,
      borderTopWidth: 1,
    },
  })(StepConnector);

  function StepperIcons(props) {
    const { active, completed } = props;

    return (
      <div
        className={clsx(classes.root, {
          [classes.active]: active,
        })}
      >
        {completed ? (
          <CheckCircleIcon style={{ color: theme.palette.green.dark }} />
        ) : active ? (
          <RadioButtonCheckedIcon
            style={{ color: theme.palette.blue.border }}
          />
        ) : (
          <RadioButtonUncheckedOutlinedIcon
            style={{ color: theme.palette.blue.border }}
          />
        )}
      </div>
    );
  }

  return (
    <Stepper
      className={clsx(classes.root, className)}
      alternativeLabel
      activeStep={activeStep}
      connector={<CustomConnector />}
    >
      {steps.map((label, index) => (
        <Step key={index}>
          <StepLabel StepIconComponent={StepperIcons} />
        </Step>
      ))}
    </Stepper>
  );
}
