import {
  Box,
  Card,
  CardContent,
  makeStyles,
  Typography,
  useTheme,
} from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import addCircleOutline from '../../assets/icons/icon-add-circle-outline.svg';
import userCheck from '../../assets/icons/icon-feather-user-check.svg';
import BottomCard from '../../components/UI/Card/BottomCard';
import { STEPS_INFOS } from '../../constants';
import Layout from '../../Layout';
import { setStep } from '../../redux/form/formSlice';

const useStyles = makeStyles((theme) => ({
  box: {
    display: 'flex',
    gap: theme.spacing(2),
  },
  card: {
    height: '35vh',
    borderRadius: '20px',
    cursor: 'pointer',
  },
  content: {
    gap: '10px',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    padding: theme.spacing(1),
  },
  title: {
    fontWeight: 'bold',
    marginLeft: theme.spacing(1),
  },
  text: {
    fontSize: '14px',
    color: theme.palette.black.main,
    fontWeight: '700',
    textAlign: 'center',
    marginBottom: '20px',
  },
  secondText: {
    font: 'normal normal 900 8px/10px Raleway',
    textAlign: 'center',
  },
  opacity: {
    opacity: 0.5,
  },
}));

const Step1 = () => {
  const theme = useTheme();
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  dispatch(setStep(STEPS_INFOS.firstStep.order));

  return (
    <Layout displayStepper={false} step={STEPS_INFOS.firstStep.stepperValue}>
      <Box marginTop='70px'>
        <Typography className={classes.title}>{'Crée ton compte'}</Typography>
      </Box>
      <BottomCard>
        <Box className={classes.box}>
          <Card
            className={classes.card}
            style={{ backgroundColor: theme.palette.green.main }}
            onClick={() => {
              history.push(STEPS_INFOS.placeOfBirth.url);
            }}
          >
            <CardContent className={classes.content}>
              <img src={addCircleOutline} alt='micro entreprise' />
              <Typography className={classes.text}>
                Je crée ma micro-entreprise
              </Typography>
            </CardContent>
          </Card>
          <Card
            className={classes.card}
            style={{ backgroundColor: theme.palette.purple.main }}
            onClick={() => console.log('in')}
          >
            <CardContent className={`${classes.content} ${classes.opacity}`}>
              <img src={userCheck} alt='micro entrepreneur' />
              <Box>
                <Typography className={classes.text}>
                  Je suis déja micro-entrepreneur
                </Typography>
                <Typography className={classes.secondText}>
                  BIENTÔT DISPONIBLE
                </Typography>
              </Box>
            </CardContent>
          </Card>
        </Box>
      </BottomCard>
    </Layout>
  );
};

export default Step1;
