import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { fetchPromoCodeByCode, patchTempPromoCode } from '../api/promoCode';
import { getUserMe, userConnected } from '../api/user';
import { STEPS_INFOS } from '../constants';
import { getStepPath } from '../utils/form';
import {
  getLocalStorageStepNumber,
  setLocalStoragePromoCode,
} from '../utils/localStorage';



const withForceStepRedirection = (Component) => (props) => {
  const UnconnectedURLs = [
    STEPS_INFOS.signUp.url,
    STEPS_INFOS.signIn.url,
    STEPS_INFOS.forgotPassword.url,
    STEPS_INFOS.resetPassword.url,
  ];
  const history = useHistory();
  const [folderSteps, setFolderSteps] = useState();
  const [hasFetchedUser, setHasFetchedUser] = useState(false);
  const lastStepNumber = parseInt(getLocalStorageStepNumber());
  const searchParams = new URLSearchParams(window.location.search);
  const promoCode = searchParams.get('promoCode');
  const token = searchParams.get('token');

  async function fetchPromoCodeAndSaveActivity(code) {
    const response = await fetchPromoCodeByCode(encodeURIComponent(code));
    if (!response?.data?.activityType?.split('/').length > 0) return;
    const activityType = response.data.activityType.split('/')[0];
    localStorage.setItem('promoCodeActivityType', activityType);
  }

  function onPromoCode(code) {
    setLocalStoragePromoCode(code);
    fetchPromoCodeAndSaveActivity(code);
    patchTempPromoCode(code);
    if (!lastStepNumber || isNaN(lastStepNumber))
      history.push(STEPS_INFOS.signUp.url);
    if (lastStepNumber === STEPS_INFOS.payment.order)
      history.push(STEPS_INFOS.signUp.url);
    history.push(getStepPath(lastStepNumber));
  }

  if (promoCode && promoCode.length > 0) {
    onPromoCode(promoCode);
  }

  useEffect(() => {
    if (UnconnectedURLs.includes(window.location.pathname)) return;
    userConnected().then((data) => {
      if (
        promoCode &&
        promoCode.length > 0 &&
        data.tempPromoCode &&
        data.tempPromoCode > 0
      )
        onPromoCode(data.tempPromoCode);
      setFolderSteps(data.folderSteps);
    }).catch(() => {
      localStorage.removeItem('user');
      localStorage.removeItem('GET_BIZ_STEP');
      history.push(STEPS_INFOS.signUp.url);
    });
  }, []);

  useEffect(() => {
    if (token && token.length > 0) {
      getUserMe(token).then((user) => {
        localStorage.setItem('user', JSON.stringify({ ...user, token: token }));
        setHasFetchedUser(true);
      });
    } else {
      if (!lastStepNumber || isNaN(lastStepNumber)) return;
      if (lastStepNumber === STEPS_INFOS.payment.order) return;
      history.push(getStepPath(lastStepNumber));
    }
  }, [lastStepNumber, folderSteps, token, history]);

  return token && !hasFetchedUser ? <>Loading</> : <Component {...props} />;
};
export default withForceStepRedirection;
