import React from "react";
import { useSelector } from "react-redux";
import { Route, Redirect } from "react-router-dom";

const ProtectedRoute = ({ component: Component, ...rest }) => {
  const user = useSelector((state) => state.user.currentUser);

  return (
    <Route
      {...rest}
      render={(props) =>
        !user || !user.token ? (
          <Redirect to="/sign-up" />
        ) : (
          <Component {...props} />
        )
      }
    />
  );
};

export default ProtectedRoute;
