import { createSlice } from "@reduxjs/toolkit";
export const activitySlice = createSlice({
  name: "activity",
  initialState: {
    activity: null,
  },
  reducers: {
    saveActivity: (state, action) => {
      state.activity = action.payload.activity;
    },
  },
});

export const { saveActivity } = activitySlice.actions;

export default activitySlice.reducer;
