export const GET_BIZ_STEP = 'GET_BIZ_STEP';
export const GET_BIZ_PROMO_CODE = 'GET_BIZ_PROMO_CODE';

export const setLocalStorageStepNumber = (value) => {
  localStorage.setItem(GET_BIZ_STEP, value);
};

export const getLocalStorageStepNumber = (value) => {
  return localStorage.getItem(GET_BIZ_STEP, value);
};

export const setLocalStoragePromoCode = (value) => {
  localStorage.setItem(GET_BIZ_PROMO_CODE, value);
};

export const getLocalStoragePromoCode = () => {
  return localStorage.getItem(GET_BIZ_PROMO_CODE);
};
