import { createSlice } from '@reduxjs/toolkit';
export const usersSlice = createSlice({
  name: 'user',
  initialState: {
    currentUser: null,
    error: null,
    isLoading: false,
  },
  reducers: {
    signInStart: (state) => (state.isLoading = true),
    signInSuccess: (state, action) => {
      state.isLoading = false;
      localStorage.setItem('user', JSON.stringify({ ...action.payload }));
      return {
        ...state,
        currentUser: { ...action.payload },
      };
    },
    signInFailure: (state, action) => {
      return { ...state, isLoading: false, error: action.payload };
    },
    resetError: (state, action) => {
      return {
        error: null,
      };
    },
    signUpSuccess: (state, action) => {
      localStorage.setItem('user', JSON.stringify({ ...action.payload }));
      return {
        ...state,
        currentUser: { ...action.payload },
      };
    },
    signUpFailure: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    rehydrateUser: (state, action) => {
      localStorage.setItem('user', JSON.stringify(action.payload));
      return {
        ...state,
        currentUser: action.payload,
      };
    },
    logout: (state) => {
      localStorage.removeItem('user');
      localStorage.removeItem('GET_BIZ_STEP');
      return {
        ...state,
        currentUser: null,
      };
    },
  },
});

export const {
  resetError,
  signInStart,
  signInSuccess,
  signInFailure,
  signUpSuccess,
  signUpFailure,
  rehydrateUser,
  logout,
} = usersSlice.actions;

export default usersSlice.reducer;
