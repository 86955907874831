import { api } from './config';

const UserAuthorizationHeaders = () => ({
  'Content-Type': 'application/json',
});

export const signUp = async (data) =>
  await Promise.resolve(
    api.post(`/auth/sign-up`, data, {
      headers: UserAuthorizationHeaders(),
    })
  ).catch((err) => Promise.reject('Cet email est déjà utilisé'));

export const signIn = async (data) =>
  await api
    .post(`/auth/sign-in`, data, {
      headers: UserAuthorizationHeaders(),
    })
    .catch((err) => {
      console.log('[D] signIn err', err);
      return err;
    });

export const forgotPassword = async (data) =>
  await api
    .post(`/auth/forgotten-password`, data, {
      headers: UserAuthorizationHeaders(),
    })
    .catch((err) => err);

export const resetPassword = async (data) =>
  await api
    .post(`/auth/reset-password`, data, {
      headers: UserAuthorizationHeaders(),
    })
    .catch((err) => err);

export const userConnected = () => {
  const user = localStorage.getItem('user');
  const token = user ? JSON.parse(user).token : null;
  return api
    .get('/auth/me', {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => res.data)
};

export const getUserMe = async (token) => {
  return await api
    .get('/auth/me', {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => res.data)
    .catch((err) => err);
};

export const createValidateFolder = async () => {
  const user = localStorage.getItem('user');
  const token = user ? JSON.parse(user).token : null;
  await api.get('/users/create-folder', {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getUserRecordStatus = async () => {
  const user = localStorage.getItem('user');
  const token = user ? JSON.parse(user).token : null;
  return await api
    .get('/records/status', {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => res.data)
    .catch((err) => err);
};

export const getUserRecordSiret = async () => {
  const user = localStorage.getItem('user');
  const token = user ? JSON.parse(user).token : null;
  return await api
    .get('/records/siret', {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => res.data)
    .catch((err) => err);
};

export const patchPhone = async (phone) =>
  await api.patch(`/users/phone`, {
    phone,
  });