import { fetchAllActivities } from '../api/activity';

let STATIC_ACTIVITIES = [];

export const getAllActivities = async () => {
  const res = await fetchAllActivities();
  STATIC_ACTIVITIES = res?.data;
  return STATIC_ACTIVITIES;
};

export const getActivityByName = (name) => {
  if (!name) return null;
  let res =
    STATIC_ACTIVITIES.find((activity) => activity.activityLevel3 === name) ??
    null;
  if (!res) {
    res =
      STATIC_ACTIVITIES.find((activity) => activity.activityLevel2 === name) ??
      null;
  }
  return res;
};

export const getActivitiesByDomain = (activityDomain) => {
  switch (activityDomain) {
    case 'Travailler via une plateforme de mise en relation':
      return getAllSubDomains();
    default:
      return STATIC_ACTIVITIES.filter(
        (elem) =>
          elem.activityLevel1 === activityDomain ||
          elem.activityLevel2 === activityDomain
      ).map((elem) =>
        elem.activityLevel3?.length > 0
          ? elem.activityLevel3
          : elem.activityLevel2
      );
  }
};

export const getAllDomains = () => {
  return Array.from(
    new Set(STATIC_ACTIVITIES.map(({ activityLevel1 }) => activityLevel1))
  );
};

export const getAllSubDomains = () => {
  return Array.from(
    new Set(
      STATIC_ACTIVITIES.map(({ activityLevel2, activityLevel3 }) =>
        activityLevel3 ? activityLevel2 : null
      )
    )
  ).filter((elem) => elem);
};
