import React from "react";
import Logo from "../../../assets/images/getbiz@2x.png";
import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
  },
  image: {
    width: "150px",
    height: "auto",
    display: "block",
    margin: "0 auto",
    paddingTop: "20px",
  },
}));

const BigLogo = () => {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <img className={classes.image} src={Logo} alt="Getbiz" />
    </Box>
  );
};

export default BigLogo;
