import { createSlice } from "@reduxjs/toolkit";

export const departmentSlice = createSlice({
  name: "department",
  initialState: {
    departments: [],
  },
  reducers: {
    fetchDepartments: (state, action) => {
      return { ...state, departments: action.payload };
    },
  },
});

export const { fetchDepartments } = departmentSlice.actions;

export default departmentSlice.reducer;
